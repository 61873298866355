import type { UUID } from "@/store/UUID.ts"
import type { RootState } from "@/store/store"

export function getRootPanelState(state: RootState) {
  return state.panels.stack[0]
}

export function getStackPanelState(state: RootState) {
  const { stack, doublePanelLayout, activePanelUuid } = state.panels
  if (activePanelUuid && stack.length > 1) {
    return stack.find((panel) => panel.panelId === activePanelUuid)
  } else {
    if (doublePanelLayout && stack.length <= 1) return null
    return stack[stack.length - 1]
  }
}

export function isPanelTableOfContentsOpen(state: RootState, panelId: UUID) {
  const panel = state.panels.stack.find((p) => p.panelId === panelId)
  return panel?.tableOfContents
}
