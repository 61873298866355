import { Button, PopoverProvider, usePopoverStore } from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useEditorState } from "@nytimes/react-prosemirror"

import styles from "./floatingMenuPopover.module.css"

import { TextSelectionPopover } from "@/components/TextSelectionPopover"
import { Quiz } from "@/components/icons"
import { useAppDispatch } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"
import { dialogOpened } from "@/store/store"

export function FloatingToolbar() {
  const dispatch = useAppDispatch()
  const editorState = useEditorState()

  const popover = usePopoverStore()

  const items = [
    {
      name: "floating-toolbar-create-magic-quiz",
      label: "Create Magic Quiz",
      Icon: Quiz,
      isDisabled: () => {
        if (!editorState) return true

        const { from, to } = editorState.selection
        return editorState.doc.textBetween(from, to).length < 10
      },
      onClick: () => {
        popover.setOpen(false)
        dispatch(dialogOpened(Dialog.INSERT_MAGIC_QUIZ))
      },
    },
  ]

  const enabledItems = items.filter((i) => !i.isDisabled())
  if (enabledItems.length === 0) return null

  return (
    <PopoverProvider store={popover}>
      <TextSelectionPopover className={styles["floatingMenu"]}>
        {enabledItems.map(({ name, label, Icon, onClick }) => {
          return (
            <Button key={name} onClick={onClick} render={<button />}>
              <Icon />
              <span>
                <Localized id={name}>{label}</Localized>
              </span>
            </Button>
          )
        })}
      </TextSelectionPopover>
    </PopoverProvider>
  )
}
