import * as Ariakit from "@ariakit/react"
import { Localized } from "@fluent/react"
import {
  useEditorEventCallback,
  useEditorEventListener,
} from "@nytimes/react-prosemirror"
import { Node } from "prosemirror-model"
import {
  type ComponentProps,
  type SVGProps,
  useEffect,
  useMemo,
  useState,
} from "react"

import styles from "./blockHandleMenu.module.css"

import { useEditorChapterSettings } from "@/components/editor/hooks/useEditorChapterSettings"
import { useIsEditorEditable } from "@/components/editor/hooks/useIsEditorEditable"
import { Checkmark, EllipsisVertical, Eraser, Link } from "@/components/icons"
import { Snackbar, enqueueSnackbar } from "@/store/reducers/snackbars"
import { padLogger } from "@/utils/debug"
import { sendErrorToSentry } from "@/utils/sentry"

type CurrentBlock = {
  node: Node
  pos: number
}

type BlockHandleMenuItem = {
  id: string
  label: string
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  Popover?: (props: {
    block: CurrentBlock | null
    anchor: HTMLElement | null
    open: boolean
    setOpen: (value: boolean) => void
  }) => JSX.Element
  onClick?: (block: CurrentBlock) => void
  isDisabled?: (block: Node) => boolean
  getState?: () => Record<string, unknown>
  setState?: (state: Record<string, unknown>) => void
}

const useMenuItems = (): BlockHandleMenuItem[] => {
  const [isEditingMarginNumber, setIsEditingMarginNumber] = useState(false)

  const isEditorEditable = useIsEditorEditable()
  const chapterSettings = useEditorChapterSettings()

  const removeMarginNumber = useEditorEventCallback<[number], void>(
    (view, pos) => {
      const node = view.state.doc.nodeAt(pos)
      if (!node?.attrs.marginNumber) return

      const tr = view.state.tr.setNodeMarkup(pos, undefined, {
        ...node.attrs,
        marginNumber: null,
      })
      tr.setNodeAttribute(pos, "marginNumberRemoved", true)

      view.dispatch(tr)
    }
  )

  return [
    {
      id: "change-margin-number",
      label: "Change Margin Number",
      Icon: (props: ComponentProps<"svg">) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          {...props}
        >
          <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
        </svg>
      ),
      Popover: UpdateMarginNumberPopover,
      isDisabled: (block) => {
        return (
          !isEditorEditable || !chapterSettings.marginNumbers?.[block.type.name]
        )
      },
      onClick: () => setIsEditingMarginNumber(true),
      getState: () => ({
        isEditing: isEditingMarginNumber,
      }),
      setState: ({ isEditing }) => {
        if (isEditing !== undefined) {
          setIsEditingMarginNumber(isEditing as boolean)
        }
      },
    },
    {
      id: "remove-margin-number",
      label: "Remove Margin Number",
      Icon: Eraser,
      isDisabled: (block) => !isEditorEditable || !hasMarginNumber(block),
      onClick: (block) => removeMarginNumber(block.pos),
    },
    {
      id: "copy-block-link",
      label: "Copy Block Link",
      Icon: Link,
      onClick: async (block) => {
        try {
          if (!block.node.attrs.guid)
            throw new Error("Block does not have a GUID")

          const { origin, pathname, search } = window.location

          const blockUrl = `${origin}${pathname.replace(
            "edit/",
            ""
          )}${search}#${block.node.attrs.guid}`

          await navigator.clipboard.writeText(blockUrl)

          enqueueSnackbar(Snackbar.LinkCopiedSuccess)
          padLogger("copied link to block", blockUrl)
        } catch (error) {
          enqueueSnackbar(Snackbar.LinkCopiedError)
          sendErrorToSentry("Could not copy link to block", error)
        }
      },
    },
  ]
}

export function BlockHandleMenu() {
  const [currentBlock, setCurrentBlock] = useState<CurrentBlock | null>(null)

  const items = useMenuItems()

  const menu = Ariakit.useMenuStore()
  const tooltip = Ariakit.useTooltipStore({ placement: "left-start" })

  const UpdateMarginNumberItem = useMemo(
    () => items.find((i) => i.id === "change-margin-number"),
    [items]
  )
  const isEditingMarginNumber =
    !!UpdateMarginNumberItem?.getState?.()?.isEditing

  useEditorEventListener("mousemove", (view, event) => {
    // Should return void to make sure that subsequent listeners (e.g. columnResizing) are called
    if (menu.getState().open || isEditingMarginNumber) return

    const pointerPos = view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    })

    // If the pointer is not inside the document, do nothing
    if (!pointerPos || pointerPos.inside < 0) return

    const $pos = view.state.doc.resolve(pointerPos.inside)
    const posAtRoot = $pos.before(1)

    const nodeElement = view.nodeDOM(posAtRoot)
    const node = view.state.doc.nodeAt(posAtRoot)

    if (node && nodeElement instanceof HTMLElement) {
      setCurrentBlock({ node, pos: posAtRoot })

      tooltip.setAnchorElement(nodeElement)
      tooltip.show()
    } else {
      setCurrentBlock(null)

      menu.hide()
      tooltip.hide()
      tooltip.setAnchorElement(null)
    }

    return
  })

  useEditorEventListener("keydown", () => {
    setCurrentBlock(null)
    menu.hide()
    tooltip.hide()
    tooltip.setAnchorElement(null)
  })

  useEffect(() => {
    if (isEditingMarginNumber) {
      tooltip.hide()
    } else {
      tooltip.render()
    }
  }, [isEditingMarginNumber, tooltip])

  if (UpdateMarginNumberItem?.Popover && isEditingMarginNumber) {
    return (
      <UpdateMarginNumberItem.Popover
        block={currentBlock}
        anchor={tooltip.getState().anchorElement}
        open={isEditingMarginNumber}
        setOpen={(value) =>
          UpdateMarginNumberItem.setState?.({ isEditing: value })
        }
      />
    )
  }

  return (
    <Ariakit.TooltipProvider store={tooltip}>
      <Ariakit.MenuProvider store={menu}>
        <Ariakit.Tooltip>
          <BlockHandleMenuButton block={currentBlock} items={items} />
        </Ariakit.Tooltip>
      </Ariakit.MenuProvider>
    </Ariakit.TooltipProvider>
  )
}

function BlockHandleMenuButton({
  block,
  items,
}: {
  block: CurrentBlock | null
  items: BlockHandleMenuItem[]
}) {
  const node = block?.node ?? null
  const withMarginNumber = hasMarginNumber(node)

  return (
    <>
      {/* `blockHandleButton` adds some invisible padding to make the button easier to click. */}
      <Ariakit.MenuButton
        className={styles.blockHandleButton}
        // re-align block handle button so that margin numbers are precisely stacked on top of each other
        style={{
          marginRight: withMarginNumber ? "-0.4rem" : undefined,
          marginTop: withMarginNumber ? "-0.6rem" : undefined,
        }}
      >
        <div className={styles.blockHandle}>
          <div className={styles.blockHandleIcon}>
            <Ariakit.VisuallyHidden>Block Menu</Ariakit.VisuallyHidden>
            <EllipsisVertical />
          </div>
          {withMarginNumber && (
            <div className={styles.marginNumber}>{node.attrs.marginNumber}</div>
          )}
        </div>
      </Ariakit.MenuButton>
      <Ariakit.Menu gutter={8} className={styles.blockHandleMenu}>
        {items.map(({ id, label, Icon, onClick, isDisabled }) => {
          return block && (!isDisabled || !isDisabled?.(block.node)) ? (
            <Ariakit.MenuItem
              key={id}
              onClick={() => onClick?.(block)}
              render={<button className={styles.blockHandleMenuItem} />}
            >
              {Icon && <Icon width={14} height={14} />}
              <Localized id={id}>{label}</Localized>
            </Ariakit.MenuItem>
          ) : null
        })}
      </Ariakit.Menu>
    </>
  )
}

const hasMarginNumber = (block: Node | null): block is Node =>
  !!block?.attrs?.marginNumber

function UpdateMarginNumberPopover({
  block,
  anchor,
  open,
  setOpen,
}: {
  block: CurrentBlock | null
  anchor: HTMLElement | null
  open: boolean
  setOpen: (value: boolean) => void
}) {
  const popover = Ariakit.usePopoverStore({
    placement: "left-start",
    open,
    setOpen,
  })

  useEffect(() => {
    if (open && anchor) {
      popover.setAnchorElement(anchor)
      popover.show()
    } else {
      popover.hide()
    }
  }, [open, anchor, popover])

  const updateMarginNumber = useEditorEventCallback<
    [CurrentBlock, string],
    boolean
  >((view, block, marginNumber) => {
    const tr = view.state.tr.setNodeMarkup(block.pos, undefined, {
      ...block.node.attrs,
      marginNumber,
      marginNumberRemoved: undefined,
    })

    view.dispatch(tr)
    return true
  })

  const form = Ariakit.useFormStore({
    defaultValues: {
      marginNumber: (block?.node.attrs?.marginNumber ?? "") as string,
    },
  })

  form.useSubmit((state) => {
    try {
      if (!block) throw new Error("Block is not defined")
      if (state.errors.marginNumber) throw new Error("Invalid Margin Number")

      updateMarginNumber(block, state.values.marginNumber)

      form.reset()
      popover.hide()
    } catch (error) {
      form.setError(form.names.marginNumber, "Error updating margin number")

      if (error instanceof Error && error.message !== "Invalid Margin Number") {
        padLogger("Error updating margin number", error)
        sendErrorToSentry("Error updating margin number", error)
      }
    }
  })

  return (
    <Ariakit.Popover
      store={popover}
      className={styles.updateMarginNumberPopover}
    >
      <Ariakit.Form store={form} className={styles.updateMarginNumberForm}>
        <Ariakit.FormSubmit disabled={!form.useState().values.marginNumber}>
          <Checkmark strokeWidth={2} />
        </Ariakit.FormSubmit>
        <div className={styles.inputField}>
          <Ariakit.VisuallyHidden>
            <Ariakit.FormLabel name={form.names.marginNumber}>
              <Localized id="margin-number">Margin Number</Localized>
            </Ariakit.FormLabel>
          </Ariakit.VisuallyHidden>
          <Ariakit.FormInput
            name={form.names.marginNumber}
            type="text"
            pattern="[a-zA-Z0-9]{1,5}"
            required
            autoFocus
          />
          <Ariakit.FormError name={form.names.marginNumber} />
        </div>
      </Ariakit.Form>
    </Ariakit.Popover>
  )
}
