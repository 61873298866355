import { HighlightButton } from "./CreateHighlightButton"
import styles from "./selecthighlightcolor.module.css"

type Props = {
  selected?: string | undefined
  disabled?: boolean | undefined
  onSelect: (color: string) => void
}

export function SelectHighlightColor({ selected, disabled, onSelect }: Props) {
  return (
    <div className={styles["colors"]}>
      <HighlightButton
        selected={selected === "yellow"}
        color="yellow"
        disabled={disabled}
        onClick={onSelect}
      />
      <HighlightButton
        selected={selected === "pink"}
        color="pink"
        disabled={disabled}
        onClick={onSelect}
      />
      <HighlightButton
        selected={selected === "green"}
        color="green"
        disabled={disabled}
        onClick={onSelect}
      />
      <HighlightButton
        selected={selected === "blue"}
        color="blue"
        disabled={disabled}
        onClick={onSelect}
      />
      <HighlightButton
        selected={selected === "violet"}
        color="violet"
        disabled={disabled}
        onClick={onSelect}
      />
    </div>
  )
}
