import { Button, usePopoverContext } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"
import clsx from "clsx"

import styles from "./createhighlightbutton.module.css"

import { CheckmarkMini } from "@/components/icons.tsx"

type Props = {
  color: string
  selected?: boolean | undefined
  disabled?: boolean | undefined
  onClick: (color: string) => void
}

export function HighlightButton({ color, selected, disabled, onClick }: Props) {
  const popover = usePopoverContext()
  const editorState = useEditorState()

  if (!editorState || !popover) return null

  return (
    <Button
      className={styles["button"]}
      contentEditable={false}
      disabled={disabled}
      onClick={() => {
        onClick(color)
        popover.hide()
      }}
    >
      <ButtonColorIndicator color={color} selected={selected} />
    </Button>
  )
}

export function ButtonColorIndicator({
  color,
  selected,
}: {
  color: string
  selected?: boolean | undefined
}) {
  return (
    <div
      className={clsx(
        styles["indicator"],
        styles[color as keyof typeof styles]
      )}
    >
      {selected && (
        <CheckmarkMini style={{ color: "white", height: "0.675rem" }} />
      )}
    </div>
  )
}
