import { Button, PopoverProvider, usePopoverStore } from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useEditorEventCallback } from "@nytimes/react-prosemirror"
import { getVersion } from "prosemirror-collab"
import { useContext } from "react"

import { SelectHighlightColor } from "./SelectHighlightColor"
import styles from "./createhighlightpopover.module.css"

import { TextSelectionPopover } from "@/components/TextSelectionPopover"
import { Pencil } from "@/components/icons"
import { AddFlashcardButton } from "@/components/viewer/flashcards/AddFlashcardButton"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useAppDispatch } from "@/store/hooks"
import { useCreateHighlightMutation } from "@/store/slices/api"
import { threadOpened } from "@/store/store"

export function CreateHighlightPopover() {
  const { revisionId, documentId: chapterId } = useContext(RevisionContext)
  const [createHighlight, { isLoading }] = useCreateHighlightMutation()

  const dispatch = useAppDispatch()

  const popoverStore = usePopoverStore()

  const onHighlightClick = useEditorEventCallback((view, color: string) => {
    return createHighlight({
      chapterId,
      revisionId,
      version: getVersion(view.state),
      highlight: {
        from: view.state.selection.from,
        to: view.state.selection.to,
        color,
        revisionId,
      },
    }).unwrap()
  })

  return (
    <PopoverProvider store={popoverStore}>
      <TextSelectionPopover className={styles["popover"]}>
        <SelectHighlightColor
          disabled={isLoading}
          onSelect={onHighlightClick}
        />
        <hr className={styles["hr"]} />
        <Button
          render={<button />}
          className={styles["menuitem"]}
          onClick={async () => {
            const highlight = await onHighlightClick("none")
            if (!highlight) return
            dispatch(
              threadOpened({
                revisionId: highlight.revisionId,
                highlightId: highlight.id,
              })
            )
          }}
        >
          <Pencil className={styles["menuicon"]} />
          <Localized id={"pad-annotations-note-add"}>Add note</Localized>
        </Button>
        <AddFlashcardButton
          chapterId={chapterId}
          revisionId={revisionId}
          className={styles["menuitem"]}
          onClick={() => {
            popoverStore.hide()
          }}
        />
        <AddFlashcardButton
          chapterId={chapterId}
          revisionId={revisionId}
          className={styles["menuitem"]}
          magic
          onClick={() => {
            popoverStore.hide()
          }}
        />
      </TextSelectionPopover>
    </PopoverProvider>
  )
}
