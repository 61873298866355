import { Button, Popover, usePopoverStore } from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"
import { useEditorEventListener } from "@nytimes/react-prosemirror"
import { Decoration, DecorationSet, EditorView } from "prosemirror-view"
import { useCallback, useContext, useState } from "react"

import { SelectHighlightColor } from "./SelectHighlightColor"
import styles from "./updatehighlightpopover.module.css"

import { useMobileAsideDrawer } from "@/components/aside/useMobileAsideDrawer"
import { Pencil, Thread, ThreadClosed, Trash } from "@/components/icons.tsx"
import { AddFlashcardButton } from "@/components/viewer/flashcards/AddFlashcardButton"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import {
  getActiveHighlightId,
  getCurrentMark,
} from "@/store/selectors/viewerSelectors"
import {
  useDeleteHighlightMutation,
  useGetHighlightsQuery,
  useUpdateHighlightMutation,
} from "@/store/slices/api"
import { viewerSlice } from "@/store/slices/viewer"
import { threadClosed, threadOpened } from "@/store/store.ts"

function decorationSize(decoration: Decoration): number {
  return decoration.to - decoration.from
}

/**
 * A popover that appears when a user clicks on an existing highlight.
 * It allows the user to delete the highlight or add a note to it.
 */
export function UpdateHighlightPopover() {
  const dispatch = useAppDispatch()
  const { documentId: chapterId, revisionId } = useContext(RevisionContext)
  const { l10n } = useLocalization()

  const popoverStore = usePopoverStore()

  const [updateHighlight, { isLoading: isUpdating }] =
    useUpdateHighlightMutation()

  const activeHighlightId = useAppSelector(getActiveHighlightId)
  const { activeHighlight } = useGetHighlightsQuery(
    { chapterId, revisionId },
    {
      selectFromResult: (result) => {
        return {
          ...result,
          activeHighlight: result.data?.find(
            (highlight) => highlight.id === activeHighlightId
          ),
        }
      },
    }
  )

  const currentThread: false | { isOpen: boolean } = useAppSelector((state) => {
    if (!activeHighlight || !revisionId) return false
    const revisionThreads = state.threads[revisionId]
    if (!revisionThreads) return false
    const thread = revisionThreads[activeHighlight.id]
    if (!thread) return false
    return thread
  })

  const [currentHighlightNode, setCurrentHighlightNode] =
    useState<HTMLElement | null>(null)

  const [deleteHighlight, { isLoading: isDeleting }] =
    useDeleteHighlightMutation()

  const asideDrawer = useMobileAsideDrawer()

  const currentMark = useAppSelector(getCurrentMark)

  const handleThreadButtonClick = useCallback(() => {
    if (!activeHighlight) return
    if (currentThread && currentThread?.isOpen && !asideDrawer) {
      dispatch(
        threadClosed({
          revisionId: activeHighlight.revisionId,
          highlightId: activeHighlight.id,
        })
      )
    } else {
      dispatch(
        threadOpened({
          revisionId: activeHighlight.revisionId,
          highlightId: activeHighlight.id,
        })
      )
      asideDrawer?.open()
    }
    popoverStore.hide()
  }, [activeHighlight, currentThread, dispatch, popoverStore, asideDrawer])

  const onHighlightClick = (view: EditorView, event: PointerEvent) => {
    if (document.getSelection() && !document.getSelection()?.isCollapsed) return
    // The pointerup event is dispatched before the
    // touchend event. If currentMark is empty, then
    // touchend is going to cancel the mark, so we
    // should treat this like a tap and continue
    if (currentMark && !currentMark.empty) return

    const { clientX, clientY } = event as MouseEvent

    const posResult = view.posAtCoords({
      left: clientX,
      top: clientY,
    })
    if (!posResult) return

    const { pos } = posResult

    const decorationSet =
      (view.props.decorations?.(view.state) as DecorationSet) ??
      DecorationSet.empty

    const clickedDecorations = decorationSet.find(
      pos,
      pos,
      (spec) => spec.type === "highlight"
    )

    if (!clickedDecorations.length) {
      dispatch(viewerSlice.actions.highlightDeactivated())
      popoverStore.hide()
      return
    }

    const smallestDecoration = clickedDecorations.reduce((acc, decoration) =>
      decorationSize(acc) < decorationSize(decoration) ? acc : decoration
    )
    dispatch(
      viewerSlice.actions.highlightActivated({
        highlightId: smallestDecoration.spec.highlight.id,
      })
    )
    popoverStore.show()
    popoverStore.render()

    setCurrentHighlightNode(
      view.domAtPos(smallestDecoration.from, 1).node.parentElement
    )
  }

  // Position the popover next to the highlight
  useEditorEventListener("pointerup", onHighlightClick)

  if (!activeHighlight) return null

  return (
    <Popover
      className={styles["popover"]}
      store={popoverStore}
      autoFocusOnShow={false}
      hideOnInteractOutside={false}
      flip={false}
      getAnchorRect={() => {
        return currentHighlightNode?.getBoundingClientRect() ?? null
      }}
    >
      <SelectHighlightColor
        disabled={isDeleting || isUpdating}
        selected={activeHighlight.color}
        onSelect={(color) => {
          updateHighlight({
            highlightId: activeHighlight.id,
            color: activeHighlight.color === color ? "none" : color,
            chapterId,
            revisionId,
          })
        }}
      />
      <Button
        className={styles["menuitem"]}
        disabled={isDeleting}
        onClick={() => {
          deleteHighlight({
            highlightId: activeHighlight.id,
            revisionId: activeHighlight.revisionId,
            chapterId,
          })
          popoverStore.hide()
        }}
      >
        <Trash className={styles["menuicon"]} />
        <Localized id={"pad-annotations-highlight-remove"}>
          Remove Highlight
        </Localized>
      </Button>
      <hr className={styles["hr"]} />
      <Button
        render={<button />}
        className={styles["menuitem"]}
        onClick={handleThreadButtonClick}
      >
        {currentThread && currentThread?.isOpen && !asideDrawer ? (
          <ThreadClosed className={styles["menuicon"]} />
        ) : activeHighlight?.threadId ? (
          <Thread className={styles["menuicon"]} />
        ) : (
          <Pencil className={styles["menuicon"]} />
        )}
        {currentThread && currentThread?.isOpen && !asideDrawer
          ? l10n.getString("pad-annotations-note-hide", null, "Hide Note")
          : activeHighlight?.threadId
          ? l10n.getString("pad-annotations-note-show", null, "Show Note")
          : l10n.getString("pad-annotations-note-add", null, "Add Note")}
      </Button>
      <AddFlashcardButton
        highlight={activeHighlight}
        chapterId={chapterId}
        revisionId={revisionId}
        className={styles["menuitem"]}
        onClick={() => {
          popoverStore.hide()
        }}
      />
      <AddFlashcardButton
        highlight={activeHighlight}
        chapterId={chapterId}
        revisionId={revisionId}
        className={styles["menuitem"]}
        magic
        onClick={() => {
          popoverStore.hide()
        }}
      />
    </Popover>
  )
}
